import React, {useEffect, useMemo, useState} from 'react';
import logo from './media/images/logo.png';
import instaImg from './media/images/product/dresses1/5.png';
import instaImg2 from './media/images/product/dresses2/4.png';
import instaImg3 from './media/images/product/dresses3/1.png';
import instaImg4 from './media/images/product/dresses2/2.png';
import instaImg5 from './media/images/product/dresses1/1.png';
import instagram from './media/instagram.png';
import needle from './media/needle.png';
import happy from './media/girl.png';

import footerImg from './media/images/footerimg1.png'
import {CollectionProducts, HomePageData, other} from "./SiteData";
import {useHistory} from "react-router-dom";
import {Footer} from "./Footer";
import {SubBanner} from "./pages/HomePage/Components/SubBanner";
import {WhatsAppButton} from "./components/WhatsAppButton";
import {SliderProducts} from "./pages/HomePage/Components/SliderProducts";
import {BannerDifferentialsComponent} from "./pages/HomePage/Components/BannerDifferentialsComponent";
import MostSaledComponent from "./pages/HomePage/Components/MostSaledComponent";
import branch from "./media/flower.png";
import {InstagramList} from "./components/InstagramList";
import videoCustom from "./media/video/hero.mp4";
import MostNewComponent from "./pages/HomePage/Components/MostNewComponent";

const indexTranslate = {
    0: 'three',
    1: 'four',
    2: 'five',
    3: 'six',
    4: 'seven',
    5: 'eight'
}

function HomePage() {
    const history = useHistory();

    function calcularDesconto(valorSemDesconto, valorComDesconto) {
        const desconto = ((valorSemDesconto - valorComDesconto) / valorSemDesconto) * 100;
        return desconto.toFixed(0) + '% OFF';
    }


    return (
        <>
            <section>
                {window.innerWidth >= 768 ?
                    <div className="item align-items-center d-flex" style={{paddingTop: 90}}>
                        <img className={'w-100'} src={HomePageData.default_banner} alt=""/>
                    </div>
                    :
                    <div style={{ width: '100%', objectFit: 'cover', position: 'relative',}}>
                        {/* <video autoPlay loop muted playsInline style={{
                            height: 400,
                            position: 'absolute',
                            zIndex: -1,
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            objectFit: 'cover'
                        }}>
                            <source src={videoCustom} type="video/mp4"/>
                        </video>*/}
                        <div className="item align-items-center d-flex" >
                            <img className={'w-100'} src={HomePageData.banner_mobile} alt=""/>
                        </div>
                        <div style={{
                            height: 400,
                            background: 'linear-gradient(1deg, white, transparent)',
                            opacity: 1,
                            position: 'absolute',
                            zIndex: -1,
                            left: 0,
                            bottom: 0,
                            width: '100%',
                            objectFit: 'cover'
                        }}/>
                        {/*  <div style={{
                            zIndex: 29,
                            height: '100%',
                            display: 'flex',
                            flexFlow: 'column',
                            justifyContent: 'center'
                        }}>
                            <h3 style={{color: 'white', marginBottom: 20}}>Vestido Personalizado</h3>
                            <span style={{color: '#e9e9e9'}}>Gostaria de fazer uma peça única? entre em contato comigo que vou te ajudar a encontrar a melhor combinação!</span>
                        </div>*/}

                    </div>
                }

                <SubBanner/>

                <MostNewComponent/>
            </section>

            <section className="banner">
                <div className="container-fluid custom-container p-0">
                    <div className="row m-0 justify-content-center align-items-center">
                        {HomePageData.promo_spots.map((item, index) =>
                            <div key={index} className="col-12 col-md-6 d-flex justify-content-center p-0">
                                {item}
                            </div>
                        )}
                    </div>
                </div>
            </section>
        {/*    <section>
                <SliderProducts/>
            </section>*/}
            <BannerDifferentialsComponent/>
            <MostSaledComponent/>

            {/*<section className="feature-area" style={{backgroundImage: `url(${footerImg})`}}>
                <div className="container-fluid custom-container">
                    <div className="row">
                         Single Feature
                        <div className="col-sm-6 col-xl-3">
                            <div className="sin-feature ">
                                <div className="inner-sin-feature px-2">
                                    <img src={instagram} style={{height: 30, marginTop: 15, width: 30}}/>

                                    <div className="f-content">
                                        <h6 className={'hover-title'}>Acompanhe a confecção pelo Instagram</h6>
                                        <p>Veja em primeira mão os novos vestidos confecionados</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                         Single Feature
                        <div className="col-sm-6  col-xl-3">
                            <div className="sin-feature">
                                <div className="inner-sin-feature  px-2">
                                    <div className="icon">
                                        <i className="flaticon-shopping-online-support" />
                                    </div>
                                    <div className="f-content">
                                        <h6 className={'hover-title'}>Contato conosco 24Hs</h6>
                                        <p>Estaremos sempre disponíveis para te ajudar e tirar dúvidas!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                         Single Feature
                        <div className="col-sm-6  col-xl-3">
                            <div className="sin-feature">
                                <div className="inner-sin-feature  px-2">
                                    <img src={needle} style={{height: 30, marginTop: 15, width: 30}}/>

                                    <div className="f-content">
                                        <h6 className={'hover-title'}>Personalizado</h6>
                                        <p>Todos os nossos vestidos são únicos feitos especialmente para sua flor</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                         Single Feature
                        <div className="col-sm-6  col-xl-3">
                            <div className="sin-feature rounded-3">
                                <div className="inner-sin-feature  px-2">
                                    <img src={happy} style={{height: 30, marginTop: 15, width: 30}}/>
                                    <div className="f-content">
                                        <h6 className={'hover-title'}>Satisfação Garantida!</h6>
                                        <p>Garantimos que sua princesa ira amar seu novo vestido</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 /.container-fluid
            </section>*/}
            {/* /.logo-carousel */}
            {/*=========================*/}
            {/*=        Testimonial Area          =*/}
            {/*=========================*/}
           {/* <section className="testimonial-area bg-one padding-120">
                <div className="container container-two">
                    <div className="section-heading pb-30">
                        <h3>Alguns de meus <span>Clientes</span></h3>
                    </div>
                     /.section-heading
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="testimonial-carousel owl-carousel owl-theme">
                                <div className="single-testimonial">
                                    <div className="tes-img">
                                        <img src="media/images/tes1.jpg" alt="" />
                                    </div>
                                    <div className="tes-content">
                                        <p>Autem vel eum iriure dolor in hendrerit ivulputate velit esse molestie consequat vel illum dolore eu olestie consequat feugiat nulla eros.</p>
                                        <span>emily watson</span>
                                    </div>
                                </div>
                                 /.single-testimonial
          single-testimonial                      <div className="single-testimonial">
                                    <div className="tes-img">
                                        <img src="media/images/tes2.png" alt="" />
                                    </div>
                                    <div className="tes-content">
                                        <p>Autem vel eum iriure dolor in hendrerit ivulputate velit esse molestie consequat vel illum dolore eu olestie consequat feugiat nulla eros.</p>
                                        <span>emily watson</span>
                                    </div>
                                </div>
                                 /.single-testimonial
                                <div className="single-testimonial">
                                    <div className="tes-img">
                                        <img src="media/images/tes1.jpg" alt="" />
                                    </div>
                                    <div className="tes-content">
                                        <p>Autem vel eum iriure dolor in hendrerit ivulputate velit esse molestie consequat vel illum dolore eu olestie consequat feugiat nulla eros.</p>
                                        <span>emily watson</span>
                                    </div>
                                </div>
                                 /.single-testimonial
                                <div className="single-testimonial">
                                    <div className="tes-img">
                                        <img src="media/images/tes2.png" alt="" />
                                    </div>
                                    <div className="tes-content">
                                        <p>Autem vel eum iriure dolor in hendrerit ivulputate velit esse molestie consequat vel illum dolore eu olestie consequat feugiat nulla eros.</p>
                                        <span>emily watson</span>
                                    </div>
                                </div>
                                 /.single-testimonial
                            </div>
                             /.testimonial-carousel
                        </div>
                         /.col-xl-12
                    </div>
                     /.row
                </div>
                 /.container
            </section>*/}
            {/* /.testimonial-area */}
            {/*=========================*/}
            {/*=   Subscribe area      =*/}
            {/*=========================*/}
            {/* subscribe-area */}
            {/*=========================*/}
            {/*=   Instagram area      =*/}
            {/*=========================*/}
            <div style={{paddingTop: 30, paddingBottom: 30, backgroundColor: '#fafafa'}} className="section-heading pb-10">

                <h3 className={'mb-0'} style={{paddingBottom: 30}}>Fique por dentro e nos siga no <span>Instagram</span></h3>
                <div className="w-100 m-auto pl-3 pr-3" style={{maxWidth: 600, color: '#7b7b7b'}}>
                      <span style={{fontSize: 15}}>
                   Descubra as novidades, lançamentos e o dia a dia do nosso ateliê seguindo-nos no Instagram.
                          <br/><br/>
                          Acompanhe nossas criações, veja bastidores e inspire-se com cada detalhe do nosso trabalho.
                          <br/><br/>
                </span>
                </div>
            </div>




            <InstagramList/>



        </>
    )
}

export default HomePage;
